export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "hexona-data"
  },
  apiGateway: {
    REGION: "us-east-1",
    //URL: "https://nvmm6m97m4.execute-api.us-east-1.amazonaws.com/test" //<== test
    URL: "https://s9rfoo2uul.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_P8cvpwMol",
    APP_CLIENT_ID: "3a9anju7jdn5bode1urk4slt68",
    IDENTITY_POOL_ID: "us-east-1:b9444c8d-fc26-4b11-9da5-979a45fd7a91"
  }
};

	// USER_POOL_ID: "us-east-1_MS7iBtFHj",
  // APP_CLIENT_ID: "7u0vkur0qlqhr6c0k9r1uv7gqs"
