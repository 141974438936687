import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import { BrowserRouter as Router, Route as PrivateRoute, Switch } from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  admin_role as adminRoutes,
  auth as authRoutes,
  landingAuth,
  landingNotAuth } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
//import LandingLayout from "../layouts/Landing";
import Page404 from "../pages/admin/auth/Page404";
import PrivateRoute from "../components/PrivateRoute";
import { useAppContext } from "../libs/contextLib";


const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <PrivateRoute
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <PrivateRoute
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

function Routes() {
  const { isAuthenticated } = useAppContext();

  const authenticated = isAuthenticated
  const landing_page = authenticated ? landingAuth : landingNotAuth
  const page_layout = authenticated ? DashboardLayout : AuthLayout
  //const landing_page = landingAuth
  //const page_layout = DashboardLayout

  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(DashboardLayout, adminRoutes)}
        {childRoutes(AuthLayout, authRoutes)}
        {childRoutes(page_layout, landing_page)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      />
      </Switch>
    </Router>
  );
}
//);

export default Routes;
