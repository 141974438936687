import React, { useState, useReducer, useEffect } from "react";
import { connect } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { AppContext } from "./libs/contextLib";

import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import aws_config from './aws_config';

import maTheme from "./theme";
import Routes from "./routes/Routes";

const aws_configuration = {
  Auth: {
    Cognito: {
      region: aws_config.cognito.REGION,
      userPoolId: aws_config.cognito.USER_POOL_ID,
      identityPoolId: aws_config.cognito.IDENTITY_POOL_ID,
      userPoolClientId: aws_config.cognito.APP_CLIENT_ID,
      mandatorySignIn: true,
      // loginWith: { },
    }
  },
  Storage: {
    region: aws_config.s3.REGION,
    bucket: aws_config.s3.BUCKET,
    identityPoolId: aws_config.cognito.IDENTITY_POOL_ID
  },
  API: {
    REST: {
      hexona: {
        endpoint:aws_config.apiGateway.URL,
        region: aws_config.apiGateway.REGION,
        aws_cognito_region: aws_config.cognito.REGION,
        aws_user_pools_id: aws_config.cognito.USER_POOL_ID,
        aws_useridentityPoolId: aws_config.cognito.IDENTITY_POOL_ID,
        aws_user_pools_web_client_id: aws_config.cognito.APP_CLIENT_ID,
      }
    }
  }
};


// Configure Amplify
Amplify.configure(aws_configuration);


function App({ theme }) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userProfile, setUserProfile] = useState( {} );
  const [globals, setGlobals] = useState( {
    backlog_record: null,
    grid_rows_per_page: 15
  } );
  const [userRole, setUserRole] = useReducer(
    (state, newState) => ({...state, ...newState}),
      {
        root: false,
        exec: false,
        admin: false,
        scrumMaster: false,
        deliveryTeam: false
      }
    );

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      // const temp_user = await Amplify.Auth.currentSession();
      const auth_session = await fetchAuthSession();
      //If user has not authenticsated, then break out of Try block so SignIn screen will be displayed
      if ( ! auth_session.tokens) {
        userHasAuthenticated(false);
        setIsAuthenticating(false);
        return;
      };

      if ( ! auth_session.tokens.accessToken.payload["cognito:groups"] ) {
        //force signout to clear out the session.  Prevents loop that you get stuck in
        await signOut();
        throw new Error("User must be assigned a Role.  Please contact your administrator");
      };

      let user_profile = {
        companies: [],
        email: auth_session.tokens.idToken.payload.email,
        user_id: auth_session.tokens.accessToken.payload.username
      };

      auth_session.tokens.accessToken.payload["cognito:groups"].forEach( group  => {
        const underscore_position = group.indexOf("_");
        const group_type = group.slice(0, underscore_position);
        const group_value = group.substring(underscore_position+1);
        group_type === "COMPANY" && user_profile.companies.push(group_value);
        group_type === "ROLE" && setUserRole( { [group_value]: true } );
      } );

      user_profile.companies = user_profile.companies.sort((a, b) => (a > b ? 1 : -1 ) );
      user_profile.company = user_profile.companies[0];

      setUserProfile(user_profile);
      userHasAuthenticated(true);
    } catch (err) {
      console.log("bummer", err);
      if (err !== 'No current user') {
        alert(err);
      }
    };

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating &&
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
          <ThemeProvider theme={maTheme[theme.currentTheme]}>
            <AppContext.Provider
              value={{
                isAuthenticated,
                userHasAuthenticated,
                userRole,
                setUserRole,
                userProfile,
                setUserProfile,
                globals,
                setGlobals
              }}>
              <Routes />
            </AppContext.Provider>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
}

export default connect(store => ({ theme: store.themeReducer }))(App);
