import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  PieChart,
  Sliders,
  User,
  Users,
  Trello,
  Clipboard
} from "react-feather";

// Hexona components
const PortfolioConfidence= async(() => import("../pages/portfolio/ConfidenceDashboard"));
const PortfolioBacklog = async(() => import("../pages/portfolio/Backlog"));
const PortfolioCompleted = async(() => import("../pages/portfolio/CompletedProjects"));
const PortfolioInitiative = async(() => import("../pages/portfolio/InitiativeDashboard"));
const PortfolioActive = async(() => import("../pages/portfolio/ActiveProjects"));
const PortfolioGrid = async(() => import("../pages/portfolio/ProjectGrid"));

const BacklogScenarioPlanning = async(() => import("../pages/backlog/ScenarioPlanning"));
const BacklogDetails = async(() => import("../pages/backlog/BacklogDetails"));

const TeamDashboard = async(() => import("../pages/team/Dashboard"));

const InitiativeDashboard = async(() => import("../pages/initiative/Dashboard"));
const MilestoneDetails = async(() => import("../pages/initiative/MilestoneDetails"));

// const ImportRAR = async(() => import("../pages/admin/ImportRAR"));
const ImportLinkedData = async(() => import("../pages/admin/ImportLinkedData"));
const TeamManagement = async(() => import("../pages/admin/TeamManagement"));
const InitiativeManagement = async(() => import("../pages/admin/InitiativeManagement"));
const BacklogManagement = async(() => import("../pages/admin/BacklogManagement"));

// Auth components
const SignIn = async(() => import("../pages/admin/auth/SignIn"), false);
const SignUp = async(() => import("../pages/admin/auth/SignUp"), false);
const Page404 = async(() => import("../pages/admin/auth/Page404"), false);
const Page500 = async(() => import("../pages/admin/auth/Page500"), false);

//const ResetPassword = async(() => import("../pages/admin/auth/ResetPassword"), false);
// Components components
const Avatars = async(() => import("../pages/material_components/Avatars"));
const Badges = async(() => import("../pages/material_components/Badges"));
const Buttons = async(() => import("../pages/material_components/Buttons"));
const Cards = async(() => import("../pages/material_components/Cards"));
const Chips = async(() => import("../pages/material_components/Chips"));
const Dialogs = async(() => import("../pages/material_components/Dialogs"));
const ExpPanels = async(() => import("../pages/material_components/ExpansionPanels"));
const Lists = async(() => import("../pages/material_components/Lists"));
const Menus = async(() => import("../pages/material_components/Menus"));
const Progress = async(() => import("../pages/material_components/Progress"));
const Snackbars = async(() => import("../pages/material_components/Snackbars"));
const Tooltips = async(() => import("../pages/material_components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const Invoice = async(() => import("../pages/pages/Invoice"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/demo_charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Docs = async(() => import("../pages/docs/Documentation"));
const Changelog = async(() => import("../pages/docs/Changelog"));


const portfolioRoutes = {
  id: "Portfolio",
  path: "/portfolio",
  //header: "Portfolio",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      //path: "/",
      path: "/portfolio/Risk",
      name: "Risk Dashboard",
      component: PortfolioConfidence
    },
    {
      path: "/portfolio/backlog",
      name: "Backlog Dashboard",
      component: PortfolioBacklog
    },
    {
      path: "/portfolio/initiative",
      name: "Initiative Dashboard",
      component: PortfolioInitiative
    },
    {
      path: "/portfolio/grid",
      name: "Portfolio Grid",
      component: PortfolioGrid
    },
    {
      path: "/portfolio/completed",
      name: "Completed Projects",
      component: PortfolioCompleted
    },
    {
      path: "/portfolio/active",
      name: "Active Projects",
      component: PortfolioActive
    }

  ]
};

const backlogRoutes = {
  id: "Backlog",
  path: "/backlog",
  //header: "Backlog",
  icon: <List />,
  children: [
    {
      path: "/backlog/backlog_details",
      name: "Backlog Details",
      component: BacklogDetails
    },
    {
      path: "/backlog/scenario_planning",
      name: "Scenario Planning",
      component: BacklogScenarioPlanning
    },
    //
    // {
    //   path: "/backlog/add_project",
    //   name: "New Project",
    //   component: AddProject
    // },
    // {
    //   path: "/backlog/active_project",
    //   name: "Activate Project",
    //   component: ActivateProject
    // },
    // {
    //   path: "/backlog/update_project",
    //   name: "Update Project",
    //   component: UpdateProject
    // },
    // {
    //   path: "/backlog/backlog_burnup",
    //   name: "Backlog Burnup",
    //   component: BacklogBurnup
    // }
  ]
};

const teamRoutes = {
  id: "Teams",
  path: "/team",
  //header: "Teams",
  icon: <Users />,
  children: [
    {
      path: "/team/dashboard",
      name: "Dashboard",
      component: TeamDashboard
    },
    // {
    //   path: "/team/completed",
    //   name: "Completed Projects",
    //   component: TeamCompletedProjects
    // },
    // {
    //   path: "/portfolio/team_info",
    //   name: "Update",
    //   component: BacklogPlanning
    // }
  ]
};

const initiativeRoutes = {
  id: "Initiatives",
  path: "/initiative",
  //header: "Backlog",
  icon: <Trello />,
  children: [
    {
      path: "/initiative/dashboard",
      name: "Dashboard",
      component: InitiativeDashboard
    },
    {
      path: "/initiative/milestone_details",
      name: "Milestone Details",
      component: MilestoneDetails
    }
  ]
};
// const planningRoutes = {
//   id: "Planning",
//   path: "/planning",
//   //header: "Backlog",
//   icon: <Clipboard />,
//   children: [
//     {
//       path: "/planning/backlog",
//       name: "Backlog",
//       component: BacklogPlanning
//     },
//     {
//       path: "/planning/project",
//       name: "Project",
//       component: ActivateProject
//     }
//   ]
// };
// const projectRoutes = {
//   id: "Project",
//   path: "/projects",
//   //header: "Teams",
//   icon: <Clipboard />,
//   children: [
//     {
//       path: "/projects/activate",
//       name: "Activate",
//       component: ActivateProject
//     }
//   ]
// };
const adminRoutes = {
  id: "Admin",
  path: "/admin",
  icon: <BookOpen />,
  children: [
    {
      path: "/admin/team_management",
      name: "Team Management",
      component: TeamManagement
    },
    {
      path: "/admin/backlog_management",
      name: "Backlog Management",
      component: BacklogManagement
    },
    {
      path: "/admin/initiative_management",
      name: "Initiative Management",
      component: InitiativeManagement
    },
    {
      path: "/admin/import_data",
      name: "Import Linked Data",
      component: ImportLinkedData
    },
    // {
    //   path: "/admin/import_rar",
    //   name: "RAR",
    //   component: ImportRAR
    // }
  ]
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <User />,
  children: [
    //{
    //  path: "/",
    //  name: "Sign In",
    //  component: SignIn
    //},
    //{
    //  path: "/auth/sign-up",
    //  name: "Sign Up",
    //  component: SignUp
    //},
    // {
    //   path: "/auth/reset-password",
    //   name: "Reset Password",
    //   component: ResetPassword
    // },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const homeAuthenticated = {
  id: "Authenticated",
  path: "/",
  name: "Confidence Dashboard",
  component: PortfolioConfidence,
  children: null
}
const homeNotAuthenticated = {
  id: "NotAuthenticated",
  path: "/",
  name: "Sign In",
  component: SignIn,
  children: null
}

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: [
    {
     path: "/auth/sign-in",
     name: "Sign In",
     component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    // {
    //   path: "/auth/reset-password",
    //   name: "Reset Password",
    //   component: ResetPassword
    // },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Examples",
  icon: <Sliders />,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      component: Default
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics
    }
  ]
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <User />,
  component: Profile,
  children: null
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null
};

const invoiceRoutes = {
  id: "Invoice",
  path: "/invoice",
  icon: <CreditCard />,
  component: Invoice,
  children: null
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  children: null
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  children: null
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  //header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs
    },
    {
      path: "/components/expansion-panels",
      name: "Expansion Panels",
      component: ExpPanels
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips
    }
  ]
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields
    }
  ]
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable
    }
  ]
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons
    }
  ]
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

const documentationRoutes = {
  id: "Getting Started",
  path: "/documentation",
  icon: <BookOpen />,
  component: Docs,
  children: null
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v1.0.5",
  icon: <List />,
  component: Changelog,
  children: null
};


export const dashboard = [
  portfolioRoutes,
  backlogRoutes,
  initiativeRoutes,
  teamRoutes,
  dashboardsRoutes,
  // planningRoutes,
  pagesRoutes,
  profileRoutes,
  projectsRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  privateRoutes
];

export const admin_role = [
  adminRoutes,
  //dashboardsRoutes,
  //pagesRoutes,
  //profileRoutes,
  //projectsRoutes,
  //invoiceRoutes,
  //tasksRoutes,
  //calendarRoutes,
  //componentsRoutes,
  //chartRoutes,
  //formsRoutes,
  //tablesRoutes,
  //iconsRoutes,
  //mapsRoutes,
  //presentationRoutes,
  //documentationRoutes,
  //changelogRoutes
];

export const auth = [authRoutes];
//export const landing = [notAuthRoutes];
export const landingNotAuth = [homeNotAuthenticated];
export const landingAuth = [homeAuthenticated];


export const sidebar_all = [
  // planningRoutes,
  portfolioRoutes,
  backlogRoutes,
  initiativeRoutes,
  teamRoutes,
];

export const sidebar_root = [
  authRoutes,
  dashboardsRoutes,
  pagesRoutes,
  profileRoutes,
  projectsRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  //presentationRoutes,
  documentationRoutes,
  changelogRoutes
];

export const sidebar_admin = [
  adminRoutes,
];

export default [
  // planningRoutes,
  portfolioRoutes,
  backlogRoutes,
  initiativeRoutes,
  teamRoutes,
  adminRoutes,
  //authRoutes,
  //dashboardsRoutes,
  //pagesRoutes,
  //profileRoutes,
  //projectsRoutes,
  //invoiceRoutes,
  //tasksRoutes,
  //calendarRoutes,
  //componentsRoutes,
  //chartRoutes,
  //formsRoutes,
  //tablesRoutes,
  //iconsRoutes,
  //mapsRoutes,
  //presentationRoutes,
  //documentationRoutes,
  //changelogRoutes
];
