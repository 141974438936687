import { blue, green, grey } from "@material-ui/core/colors";

const hexonaVariant = {
  name: "Hexona",
  palette: {
    primary: {
      main: "#CE5C17",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#002868",
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFFFFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: blue[600]
    }
  },
  sidebar: {
    color: grey[200],
    background: "#002868",
    header: {
      color: grey[200],
      background: "#CE5C17",
      brand: {
        color: "#CE5C17"
      }
    },
    footer: {
      color: grey[200],
      background: "#232f3e",
      online: {
        background: green[500]
      }
    },
    badge: {
      color: "#FFF",
      background: "#CE5C17"
    }
  },
  body: {
    background: "#F1F1F1"
    //background: "#F7F9FC"
  },
  charts: {
    tooltip_background: "#F1F1F1",
    completed_early: "#27AE60",
    completed_late: "#E74C3C",
    color_10: "#7DCEA0",
    color_50: "#27AE60",
    color_90: "#1E8449",
  },
  confidence: {
    low: "#BF0A30",
    medium: "#FED700",
    medium_text: "#CE5C17",
    high: "#1E8449",
    complete: "#202020",
    over_deliver: "#002868",
    under_deliver: "#566573",
  }
};

const variants = [
  hexonaVariant,
];

export default variants;
