import React, { useEffect } from "react";
import { Route } from "react-router-dom";
//import { useAuth0 } from "../modules/api/Auth_Wrapper.js";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticating, isAuthenticated } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticating || isAuthenticated) {
      return;
    }

    history.push("/");
    //const fn = async () => {
    //  await loginWithRedirect({
    //    appState: {targetUrl: window.location.pathname}
    //  });
    //};
    //fn();

    //Makes the useEffect dependency warning message go away
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticating, isAuthenticated]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
