import React from "react";
//import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
// import { darken } from "polished";
//import { Link, useHistory } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import { useAppContext } from "../libs/contextLib";
import {
  // Badge,
  Button as MuiButton,
  FormControl as MuiFormControl,
  Grid,
  Hidden,
  Link,
  // InputBase,
  InputLabel,
  // Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  // Paper as MuiPaper,
  Select as MuiSelect,
  Toolbar,
  Typography as MuiTypography
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import {
  //Bell,
  //MessageSquare,
  // Search as SearchIcon,
  User,
  //Power
} from "react-feather";
import { spacing } from "@material-ui/system";

//const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Button = styled(MuiButton)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
// const Typography = styled(MuiTypography)(spacing);
// const Paper = styled(MuiPaper)(spacing);
const Select = styled(MuiSelect)(spacing);
const IconButton = styled(MuiIconButton)`
  svg {
    width: 30px;
    height: 30px;
  }
`;

const Typography = styled(MuiTypography)`
  color: ${props => props.theme.palette.secondary.main};
`;



// const Paper = styled(MuiPaper)`
//   marginLeft: 20px;
// `;

// const Indicator = styled(Badge)`
//   .MuiBadge-badge {
//     background: ${props => props.theme.header.indicator.background};
//     color: ${props => props.theme.palette.common.white};
//   }
// `;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${props => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;
//
//   &:hover {
//     background-color: ${props => darken(0.05, props.theme.header.background)};
//   }
//
//   ${props => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;
//
// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//
//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;
//
//   > input {
//     color: ${props => props.theme.header.search.color};
//     padding-top: ${props => props.theme.spacing(2.5)}px;
//     padding-right: ${props => props.theme.spacing(2.5)}px;
//     padding-bottom: ${props => props.theme.spacing(2.5)}px;
//     padding-left: ${props => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

// const Flag = styled.img`
//   border-radius: 50%;
//   width: 22px;
//   height: 22px;
// `;

// class LanguageMenu extends Component {
//   state = {
//     anchorMenu: null
//   };
//
//   toggleMenu = event => {
//     this.setState({ anchorMenu: event.currentTarget });
//   };
//
//   closeMenu = () => {
//     this.setState({ anchorMenu: null });
//   };
//
//   render() {
//     const { anchorMenu } = this.state;
//     const open = Boolean(anchorMenu);
//
//     return (
//       <React.Fragment>
//         <IconButton
//           aria-owns={open ? "menu-appbar" : undefined}
//           aria-haspopup="true"
//           onClick={this.toggleMenu}
//           color="inherit"
//         >
//           <Flag src="/static/img/flags/us.png" alt="English" />
//         </IconButton>
//         <Menu
//           id="menu-appbar"
//           anchorEl={anchorMenu}
//           open={open}
//           onClose={this.closeMenu}
//         >
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             English
//           </MenuItem>
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             French
//           </MenuItem>
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             German
//           </MenuItem>
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             Dutch
//           </MenuItem>
//         </Menu>
//       </React.Fragment>
//     );
//   }
// }

// class UserMenu extends Component {
//   state = { anchorMenu: null };
//
//   toggleMenu = event => {
//     this.setState({ anchorMenu: event.currentTarget });
//   };
//
//   closeMenu = () => {
//     this.setState({ anchorMenu: null });
//   };
//
//   render() {
//     const { anchorMenu } = this.state;
//     const open = Boolean(anchorMenu);
//
//     const isSignedIn = this.props.authenticated
//     const logout = this.props.logout
//
//     return (
//       <React.Fragment>
//         <IconButton
//           aria-owns={open ? "menu-appbar" : undefined}
//           aria-haspopup="true"
//           onClick={this.toggleMenu}
//           color="inherit"
//         >
//           <Power />
//         </IconButton>
//         <Menu
//           id="menu-appbar"
//           anchorEl={anchorMenu}
//           open={open}
//           onClose={this.closeMenu}
//         >
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             Profile
//           </MenuItem>
//           {isSignedIn && <MenuItem onClick={() => logout()}>Sign out</MenuItem>}
//         </Menu>
//       </React.Fragment>
//     );
//   }
// }

//const Header = ({ onDrawerToggle }) => (
function Header({ onDrawerToggle }) {
  const history = useHistory();
  const { isAuthenticated, userHasAuthenticated, setUserRole, userProfile, setUserProfile } = useAppContext();

  async function handleLogout() {
    // await Amplify.Auth.signOut();
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    };

    userHasAuthenticated(false);
    setUserRole( {
        root: false,
        exec: false,
        admin: false,
        scrumMaster: false,
        deliveryTeam: false
    } )

    history.push("/");
  };

  const handleCompanyChange = (event) => {
    let user_profile = userProfile;
    user_profile.company = event.target.value;
    setUserProfile(user_profile);

    history.push(history.location.pathname);
  };

  const companies = userProfile.companies.sort((a, b) => (a > b ? 1 : -1 ) );
  const company_list = companies.map( company  => {
    return <MenuItem key={company} name="company_id" value={company}>{company}</MenuItem>;
  } );

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item>
            <IconButton color="inherit">
              <User />
            </IconButton>
            <FormControl mb={3}>
              <InputLabel htmlFor="sub_initiative">
                <Typography variant="h3" gutterBottom>{userProfile.email} </Typography>
              </InputLabel>
              <br />
              <Select value={userProfile.company} name="company" onChange={handleCompanyChange} >
                {company_list}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs />
          <Grid item xs>
            <Typography variant="h6" id="HW_badge_cont">
              <span id="HW_badge">
                <Link target="_blank" href="https://headwayapp.co/app-hexona-updates" color="inherit" rel="noreferrer">
                  News and Updates
                </Link>
              </span>
            </Typography>

          </Grid>

          <Grid item>
          {/*
            <IconButton color="inherit">

              <Indicator badgeContent={3}>
                <MessageSquare />
              </Indicator>
            </IconButton>
            <IconButton color="inherit">
              <Indicator badgeContent={7}>
                <Bell />
              </Indicator>

            </IconButton>
            <LanguageMenu />
            */}



            <Button
              onClick={handleLogout}
              disabled={!isAuthenticated}
              fullWidth
              variant="contained"
              color="primary"
              mb={2}
            >
              Logout
            </Button>

          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>

  );

}

export default connect()(withTheme(Header));
