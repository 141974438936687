import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import history from "./utils/history";

import { Provider } from 'react-redux';
import store from './redux/store/index';
// import { Amplify } from 'aws-amplify';
// import aws_config from './aws_config';

// A function that routes the user to the right place
// after login

// Amplify.configure({
//   Auth: {
//     Cognito: {
//       region: aws_config.cognito.REGION,
//       userPoolId: aws_config.cognito.USER_POOL_ID,
//       identityPoolId: aws_config.cognito.IDENTITY_POOL_ID,
//       userPoolWebClientId: aws_config.cognito.APP_CLIENT_ID,
//       mandatorySignIn: true,
//       loginWith: {
//
//       }
//     }
//   },
//   Storage: {
//     region: aws_config.s3.REGION,
//     bucket: aws_config.s3.BUCKET,
//     identityPoolId: aws_config.cognito.IDENTITY_POOL_ID
//   },
//   API: {
//     endpoints: [
//       {
//         name: "hexona",
//         endpoint: aws_config.apiGateway.URL,
//         region: aws_config.apiGateway.REGION
//       },
//     ]
//   }
// });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));
